@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }

    .keep-together {
        break-inside: avoid;
    }

    .page-break {
        page-break-after: always;
    }
}

.cabecera {
    border-style: double;
}


.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}